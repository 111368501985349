import * as React from 'react';
import {Helmet} from 'react-helmet';

import {GlobalStyle} from '../styles/global';

import {Header} from '../components/header/header';
import {Hero} from '../components/sections/hero/hero';
import {Features} from '../components/sections/features/features';
import {Assist} from '../components/sections/assist/assist';
import {Business} from '../components/sections/business/business';
import {Personal} from '../components/sections/personal/personal';
import {Intelligence} from '../components/sections/intelligence/intelligence';
import {Users} from '../components/sections/users/users';
import {Footer} from '../components/footer/footer';

const IndexPage = () => (
  <>
    <GlobalStyle />

    <Helmet>
      <html lang="ru" />
      <title>ПроЧек</title>

      <meta
        name="description"
        content="Мобильное приложение ПроЧек поможет вам отказаться от использования бумажных кассовых чеков. Получайте кассовые чеки автоматически, организовываете хранение электронных чеков, делитесь и предоставляете доступ другим людям к своим электронным чекам."
      />

      <meta name="yandex-verification" content="e734b2fe418209ba" />

      <script type="text/javascript">
        {`
          (function (m, e, t, r, i, k, a) {
            m[i] =
              m[i] ||
              function () {
                (m[i].a = m[i].a || []).push(arguments);
              };
            m[i].l = 1 * new Date();
            (k = e.createElement(t)),
              (a = e.getElementsByTagName(t)[0]),
              (k.async = 1),
              (k.src = r),
              a.parentNode.insertBefore(k, a);
          })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');
          
          ym(88433229, 'init', {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
          });
        `}
      </script>

      <script type="text/javascript">
        {`
          !(function () {
            var t = document.createElement('script');
            (t.type = 'text/javascript'),
              (t.async = !0),
              (t.src = 'https://vk.com/js/api/openapi.js?169'),
              (t.onload = function () {
                VK.Retargeting.Init('VK-RTRG-1361028-2Dj0g'), VK.Retargeting.Hit();
              }),
              document.head.appendChild(t);
          })();
        `}
      </script>
    </Helmet>

    <Header />
    <main>
      <Hero />
      <Features />
      <Assist />
      <Business />
      <Personal />
      <Intelligence />
      <Users />
    </main>
    <Footer />

    <noscript>
      <div>
        <img
          src="https://mc.yandex.ru/watch/88433229"
          style={{position: 'absolute', left: '-9999px'}}
          alt=""
        />
      </div>
    </noscript>

    <noscript>
      <img
        src="https://vk.com/rtrg?p=VK-RTRG-1361028-2Dj0g"
        style={{position: 'fixed', left: '-9999px'}}
        alt=""
      />
    </noscript>
  </>
);

export default IndexPage;
