import * as React from 'react';
import styled from 'styled-components';

import {Container} from '../common/container/container';
import {Menu} from './menu';
import {Logo} from './logo';

export const Header = () => (
  <Root>
    <Container>
      <Inner>
        <Logo />
        <Menu />
      </Inner>
    </Container>
  </Root>
);

const Root = styled.header`
  padding: 20px 0;

  @media (min-width: 1280px) {
    padding-top: 35px;
    padding-bottom: 60px;
  }
`;

const Inner = styled.div`
  display: grid;
  gap: 15px;
  
  @media (min-width: 1280px) {
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
  }
`;
