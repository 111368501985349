import * as React from 'react';
import styled from 'styled-components';

export const CheckList = styled.ul`
  list-style: none;
  padding: 0;
`;

const CheckListItemInner = styled.div`
  position: relative;
  padding-left: 45px;
  font-size: 20px;
  font-weight: 400;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    width: 20px;
    height: 10px;
    border-left: 2px solid #6895f5;
    border-bottom: 2px solid #6895f5;
    transform: rotate(310deg);
  }
`;

const Item = styled.li`
  &:not(:last-child) {
    margin-bottom: 1.7em;
  }
`;

export const CheckListItem = ({children}) => (
  <Item>
    <CheckListItemInner>{children}</CheckListItemInner>
  </Item>
);
