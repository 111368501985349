import googlePlay from './google.svg';
import googlePlayDark from './google-dark.svg';

import appStore from './app-store.svg';
import appStoreDark from './app-store-dark.svg';

import ruStore from './ru-store.svg';
import ruStoreDark from './ru-store-dark.svg';

import nashStore from './nash-store.svg';
import nashStoreDark from './nash-store-dark.svg';

import proChek from '../images/icon.png';

export const stores = [
  {
    name: 'Google Play',
    href: 'https://play.google.com/store/apps/details?id=ru.stmlabs.procheck.app',
    icon: {
      light: googlePlay,
      dark: googlePlayDark,
    },
  },
  {
    name: 'App Store',
    href: 'https://apps.apple.com/us/app/%D0%BF%D1%80%D0%BE%D1%87%D0%B5%D0%BA/id1587687415',
    icon: {
      light: appStore,
      dark: appStoreDark,
    },
  },
  {
    name: 'RuStore',
    href: 'https://apps.rustore.ru/app/ru.stmlabs.procheck.app',
    icon: {
      light: ruStore,
      dark: ruStoreDark,
    },
  },
  {
    name: 'NashStore',
    href: 'https://nashstore.ru/',
    icon: {
      light: nashStore,
      dark: nashStoreDark,
    },
  },
  {
    name: 'Веб версия',
    href: 'https://lk.pro-chek.ru',
    isWeb: true,
    icon: proChek,
  },
];
