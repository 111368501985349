import * as React from 'react';
import styled from 'styled-components';

import {Section} from '../../common/section/section';

import cards from './icons/cards.svg';
import users from './icons/users.svg';
import cart from './icons/cart.svg';

const assist = [
  {
    name: 'Владельцам бизнес карт',
    description:
      'Упрощает процесс отчетности и работу с фискальными документами',
    icon: cards,
  },
  {
    name: 'Пользователям',
    description: 'Помогает контролировать свои финансы',
    icon: users,
  },
  {
    name: 'При совместных покупках',
    description:
      'Позволяет разделить траты и обменяться чеками с помощью функций «Совместная покупка» и «События»',
    icon: cart,
  },
];

export const Assist = () => (
  <Section accented>
    <Items>
      {assist.map((item, index) => (
        <Item key={index}>
          <ItemInner>
            <Card>
              <ItemIcon src={item.icon} width={68} height={68} alt="" />
              <ItemHeading>{item.name}</ItemHeading>
              <ItemDescription>{item.description}</ItemDescription>
            </Card>
          </ItemInner>
        </Item>
      ))}
    </Items>
  </Section>
);

const Items = styled.ul`
  display: grid;
  gap: 40px;
  list-style: none;
  padding: 0;

  @media (min-width: 1280px) {
    gap: 100px;
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Item = styled.li`
  display: flex;

  > * {
    width: 100%;
  }
`;

const ItemInner = styled.div`
  width: 100%;
  height: 100%;

  @media (min-width: 1280px) {
    padding-top: 36px;
  }
`;

const Card = styled.div`
  height: 100%;
  position: relative;
  padding: 30px;
  padding-bottom: 50px;
  background: #fff;
  box-shadow: 0 12px 40px rgba(104, 149, 245, 0.12);
  border-radius: 12px;

  @media (min-width: 1280px) {
    padding-top: 70px;
  }
`;

const ItemIcon = styled.img`
  margin-bottom: 10px;

  @media (min-width: 1280px) {
    position: absolute;
    top: -35px;
  }
`;

const ItemHeading = styled.p`
  margin-bottom: 0.4em;
  font-size: 20px;
  color: #192f5e;
`;

const ItemDescription = styled.p`
  @media (min-width: 1280px) {
    max-width: 260px;
  }
`;
