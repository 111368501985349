import * as React from 'react';
import styled from 'styled-components';

import {stores} from '../../../app-stores';

export const Stores = () => (
  <Root>
    {stores.map((store, index) => (
      <li key={index}>
        <Link href={store.href} target="_blank">
          {
            store.isWeb
              ? <Web>
                  <img src={store.icon} width={27} height={27} alt={store.name} />
                  <div>{store.name}</div>
                </Web>
              : <Img src={store.icon.light} width={138} height={40} alt={store.name} />
          }
        </Link>
      </li>
    ))}
  </Root>
);

const Root = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  gap: 12px;

  @media (min-width: 1280px) {
    width: 290px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Link = styled.a`
  display: block;
  text-decoration: none;
`;

const Img = styled.img`
  display: block;
`;


const Web = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #2e2e2e;
  color: black;
  border-radius: 6px;
  width: 138px;
  height: 40px;
  padding: 5px 0 5px 10px;
  
  img {
    margin-right: 4px;
  }
`;
