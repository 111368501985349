import * as React from 'react';
import styled from 'styled-components';

const items = [
  {
    name: 'О приложении',
    href: '#about',
  },
  {
    name: 'Бизнесу',
    href: '#business',
  },
  {
    name: 'Персональное использование',
    href: '#personal',
  },
  {
    name: 'Искусственный интеллект',
    href: '#intelligence',
  },
];

export const Menu = () => (
  <Root>
    {items.map((item, index) => (
      <Item key={index}>
        <Link href={item.href}>{item.name}</Link>
      </Item>
    ))}
  </Root>
);

const Root = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 1280px) {
    overflow-x: auto;
  }
`;

const Item = styled.li`
  &:not(:last-child) {
    margin-right: 18px;

    @media (min-width: 1280px) {
      margin-right: 32px;
    }
  }
`;

const Link = styled.a`
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  color: #192f5e;

  &:hover {
    color: #6895f5;
  }
`;
