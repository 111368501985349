import * as React from 'react';
import styled from 'styled-components';

import {stores} from '../../../app-stores';

export const Stores = () => {
  return (
    <Root>
      {stores.map((store, index) => (
        <li key={index}>
          <Link href={store.href} target="_blank">
            {
              store.isWeb
                ? <Web>
                    <img src={store.icon} width={32} height={32} alt={store.name} />
                    <div>{store.name}</div>
                  </Web>
                : <img src={store.icon.dark} width={180} height={52} alt={store.name} />
            }
          </Link>
        </li>
      ))}
    </Root>
  );
};

const Root = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  width: 325px;

  @media (min-width: 1280px) {
    width: 345px;
  }
`;

const Link = styled.a`
  display: block;
  text-decoration: none;

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`;

const Web = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #2e2e2e;
  border-radius: 6px;
  color: white;
  width: 165px;
  height: 48px;
  padding: 5px 0 5px 12px;
  font-size: 18px;
  
  img {
    margin-right: 7px;
  }
`;
