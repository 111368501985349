import React from 'react';
import styled from 'styled-components';
import {useInView} from 'react-intersection-observer';

export const LazyAnimation = ({children, className, as}) => {
  const {ref, inView} = useInView({threshold: 0.6, triggerOnce: true});

  return (
    <Root className={className} as={as} ref={ref} inView={inView}>
      {children}
    </Root>
  );
};

const Root = styled.div`
  opacity: ${p => (p.inView ? 1 : 0.5)};
  transform: scale(${p => (p.inView ? 1 : 0.9)});
  transition: opacity 1s ease, transform 1s ease;
`;
