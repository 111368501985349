import * as React from 'react';
import styled from 'styled-components';

import {Heading as RawHeading} from '../../common/heading/heading';
import {CheckList, CheckListItem} from '../../common/check-list/check-list';
import {LazyAnimation} from '../../common/lazy-animation/lazy-animation';
import {Section} from '../../common/section/section';

import image from './image.svg';

export const Personal = () => (
  <Section accented id="personal">
    <Inner>
      <Main>
        <Heading as="h2">Контроль финансов проще с ПроЧек</Heading>

        <Additional>Для персонального использования</Additional>

        <CheckList>
          <CheckListItem>
            Больше не нужно вести расходы вручную. Организуйте получение чеков
            при совершении покупки по email или номеру телефону
          </CheckListItem>
          <CheckListItem>
            Все траты будут учтены. Для покупок без чека можно создать «расход»
          </CheckListItem>
          <CheckListItem>
            Анализируете сведения о тратах по категориям
          </CheckListItem>
        </CheckList>
      </Main>

      <ImageContainer>
        <LazyAnimation>
          <Img src={image} width={542} height={552} alt="" />
        </LazyAnimation>
      </ImageContainer>
    </Inner>
  </Section>
);

const Inner = styled.div`
  display: grid;
  gap: 60px;
  grid-template-areas:
    'main'
    'image';

  @media (min-width: 1280px) {
    grid-template-areas: 'image main';
    grid-template-columns: auto 1fr;
    gap: 135px;
    align-items: center;
  }
`;

const Main = styled.div`
  grid-area: main;
`;

const Heading = styled(RawHeading)`
  margin-bottom: 0.6em;
`;

const Additional = styled.p`
  margin-bottom: 1.6em;
  font-size: 20px;
  color: #5f8cec;
  text-transform: uppercase;
`;

const ImageContainer = styled.div`
  grid-area: image;
`;

const Img = styled.img`
  height: auto;
  max-width: 100%;
  display: block;
`;
