import {createGlobalStyle} from 'styled-components';

import {FONT_FAMILY_NAME, fonts} from './fonts';
import bgImage from '../images/bg-image.svg';

export const GlobalStyle = createGlobalStyle`
  ${fonts}

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body,
  h1,
  h2,
  h3,
  p,
  ul,
  li {
    margin: 0;
  }

  p,
  h1,
  h2,
  h3 {
    overflow-wrap: break-word;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }
  
  body {
    font-family: ${FONT_FAMILY_NAME}, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    background-image: url("${bgImage}");
    background-repeat: no-repeat;
    background-position: 0 370px;
    background-size: 25vw;
    color: #646b78;
  }
`;
