import * as React from 'react';
import styled from 'styled-components';

import logo from './logo.svg';

export const Logo = () => (
  <a href="/">
    <Img src={logo} width={131} height={33} alt="ПроЧек" />
  </a>
);

const Img = styled.img`
  display: block;
`;