import * as React from 'react';
import styled from 'styled-components';

import {Heading as RawHeading} from '../../common/heading/heading';
import {Section} from '../../common/section/section';

import download from './icons/download.svg';
import list from './icons/list.svg';
import search from './icons/search.svg';
import stats from './icons/stats.svg';
import categories from './icons/categories.svg';
import folder from './icons/folder.svg';

const features = [
  {
    name: 'Загрузка чеков',
    description: 'Автоматически: по e-mail, телефону или путем сканирования',
    icon: download,
  },
  {
    name: 'Отчетность',
    description:
      'Данные можно выгрузить в pdf, отправить на e-mail или в мессенджер',
    icon: list,
  },
  {
    name: 'Поиск',
    description: 'Поиск по категориям и товарам помогает быстро находить чеки',
    icon: search,
  },
  {
    name: 'Статистика трат',
    description:
      'Статистика по категориям, покупки без чеков можно добавить вручную, и ПроЧек учтет их',
    icon: stats,
  },
  {
    name: 'Категории',
    description:
      'Товарные категории определяет искусственный интеллект, точность выше 90%',
    icon: categories,
  },
  {
    name: 'Хранение данных',
    description:
      'ПроЧек хранит данные и позволяет отслеживать, как меняются цены во времени или в разных магазинах',
    icon: folder,
  },
];

export const Features = () => (
  <Section id="about">
    <Heading as="h2">Возможности ПроЧек</Heading>

    <Items>
      {features.map((feature, index) => (
        <Item key={index}>
          <ItemInner>
            <img src={feature.icon} width={40} height={40} alt="" />
            <div>
              <ItemHeading>{feature.name}</ItemHeading>
              <ItemDescription>{feature.description}</ItemDescription>
            </div>
          </ItemInner>
        </Item>
      ))}
    </Items>
  </Section>
);

const Heading = styled(RawHeading)`
  margin-bottom: 50px;

  @media (min-width: 1280px) {
    text-align: center;
    margin-bottom: 75px;
  }
`;

const Items = styled.ul`
  display: grid;
  gap: 40px;
  list-style: none;
  padding: 0;

  @media (min-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 80px;
  }
`;

const Item = styled.li``;

const ItemInner = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;

  @media (min-width: 1280px) {
    gap: 30px;
  }
`;

const ItemHeading = styled.p`
  margin-bottom: 0.4em;
  font-size: 20px;
  color: #192f5e;
`;

const ItemDescription = styled.p``;
