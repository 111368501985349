import * as React from 'react';
import styled from 'styled-components';

import {Container} from '../container/container';

export const Section = ({accented, narrow, children, ...props}) => (
  <Root accented={accented} narrow={narrow} {...props}>
    <Container>{children}</Container>
  </Root>
);

const Root = styled.div`
  padding: ${p => p.narrow ? '40px' : '70px'} 0;
  background: ${p => p.accented ? '#f9faff' : 'transparent'};

  @media (min-width: 1280px) {
    padding: ${p => p.narrow ? '40px' : '100px'} 0;
  }
`;
