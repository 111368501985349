import * as React from 'react';
import styled from 'styled-components';

import {Heading as HeadingRaw} from '../../common/heading/heading';
import {LazyAnimation} from '../../common/lazy-animation/lazy-animation';
import {Stores} from './stores';
import screens from './screens.svg';
import {Section} from '../../common/section/section';

export const Hero = () => (
  <Section narrow>
    <Inner>
      <div>
        <Heading as="h1">
          Хранение и управление чеками в одном приложении
        </Heading>
        <List>
          <li>Получай свои чеки быстро и без сканирования</li>
          <li>Совершай совместные покупки</li>
          <li>Делись своими чеками</li>
          <li>Храни чеки в группах</li>
        </List>
        <Stores />
      </div>
      <div>
        <LazyAnimation delayed>
          <img src={screens} width={523} height={508} alt="" />
        </LazyAnimation>
      </div>
    </Inner>
  </Section>
);

const Heading = styled(HeadingRaw)`
  margin-bottom: 0.7em;
`;

const List = styled.ul`
  display: grid;
  gap: 6px;
  padding-left: 20px;
  margin-bottom: 25px;

  @media (min-width: 1280px) {
    margin-bottom: 40px;
  }
`;

const Inner = styled.div`
  display: grid;
  gap: 60px;

  @media (min-width: 1280px) {
    grid-template-columns: 600px 525px;
    align-items: center;
    justify-content: space-between;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`;
