import * as React from 'react';
import styled from 'styled-components';

import logo from './stm-logo.svg';

export const Logo = () => (
  <Root href="https://stm-labs.ru/" target="_blank">
    <img src={logo} width={106} height={24} alt="" />
  </Root>
);

const Root = styled.a`
  display: block;

  img {
    display: block;
  }
`;
