import * as React from 'react';
import styled from 'styled-components';

import {Heading as RawHeading} from '../../common/heading/heading';
import {CheckList, CheckListItem} from '../../common/check-list/check-list';
import {LazyAnimation} from '../../common/lazy-animation/lazy-animation';
import {Section} from '../../common/section/section';

import image from './image.svg';

export const Intelligence = () => (
  <Section id="intelligence">
    <Inner>
      <div>
        <Heading as="h2">Искусственный интеллект</Heading>

        <CheckList>
          <CheckListItem>
            ПроЧек определит товарную категорию за вас. <br/> Точность составляет
            более 90%
          </CheckListItem>
          <CheckListItem>
            Автоматическая категоризация определит категории новых чеков
            и товарных позиций. При необходимости можно отключить эту функцию
          </CheckListItem>
          <CheckListItem>
            Вы можете просматривать данные по ценам. <br/> Данные электронных чеков
            не теряются и их легко найти
          </CheckListItem>
        </CheckList>
      </div>

      <LazyAnimation>
        <Img src={image} width={444} height={333} alt="" />
      </LazyAnimation>
    </Inner>
  </Section>
);

const Inner = styled.div`
  display: grid;
  gap: 70px;

  @media (min-width: 1280px) {
    grid-template-columns: 1fr auto;
    gap: 200px;
    align-items: center;
  }
`;

const Heading = styled(RawHeading)`
  margin-bottom: 1em;
`;

const Img = styled.img`
  height: auto;
  max-width: 100%;
  display: block;
`;
