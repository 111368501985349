import * as React from 'react';
import styled from 'styled-components';

import {Heading as RawHeading} from '../../common/heading/heading';
import {CheckList, CheckListItem} from '../../common/check-list/check-list';
import {Section} from '../../common/section/section';
import {LazyAnimation} from '../../common/lazy-animation/lazy-animation';

import loading from './loading.svg';

export const Business = () => (
  <Section id="business">
    <Inner>
      <div>
        <Heading as="h2">
          ПроЧек упрощает процессы для владельцев бизнес-карт
        </Heading>

        <CheckList>
          <CheckListItem>
            Упрощение процессов отчетности и работы с фискальными документам
          </CheckListItem>
          <CheckListItem>
            Чеки могут быть получены сразу в приложение по email, номеру
            телефона или путем сканирования
          </CheckListItem>
          <CheckListItem>
            Поиск внутри приложения и возможность присвоения категории чеку
            поможет быстро найти нужный документ
          </CheckListItem>
          <CheckListItem>
            Приложение поддерживает возможность выгрузки данных в pdf и csv
            форматах для формирования отчетности
          </CheckListItem>
        </CheckList>
      </div>

      <LazyAnimation>
        <Img src={loading} width={426} height={552} alt="" />
      </LazyAnimation>
    </Inner>
  </Section>
);

const Inner = styled.div`
  display: grid;
  gap: 70px;

  @media (min-width: 1280px) {
    grid-template-columns: 1fr auto;
    gap: 200px;
    align-items: center;
  }
`;

const Heading = styled(RawHeading)`
  margin-bottom: 1em;
`;

const Img = styled.img`
  height: auto;
  max-width: 100%;
  display: block;
`;
