import * as React from 'react';
import styled from 'styled-components';

import {Heading as RawHeading} from '../../common/heading/heading';
import {LazyAnimation} from '../../common/lazy-animation/lazy-animation';
import {Section} from '../../common/section/section';

import events from './icons/events.svg';
import groups from './icons/groups.svg';
import jointly from './icons/jointly.svg';

const users = [
  {
    name: 'Совместная покупка',
    description:
      'Функция поможет организовать совместные покупки и определить расходы',
    icon: jointly,
  },
  {
    name: 'События',
    description:
      'Функция поможет определить расходы на компанию и организовать обмен чеками',
    icon: events,
  },
  {
    name: 'Группы',
    description:
      'Добавьте друзей в группу для совместного доступа к чекам. Чек доступен всем участникам группы',
    icon: groups,
  },
];

export const Users = () => (
  <Section>
    <Heading as="h2">Взаимодействие с другими пользователями</Heading>

    <Items>
      {users.map((user, index) => (
        <LazyAnimation as="li" key={index}>
          <ItemInner>
            <img src={user.icon} width={220} height={277} alt="" />
            <div>
              <ItemHeading>{user.name}</ItemHeading>
              <ItemDescription>{user.description}</ItemDescription>
            </div>
          </ItemInner>
        </LazyAnimation>
      ))}
    </Items>
  </Section>
);

const Heading = styled(RawHeading)`
  margin-bottom: 50px;

  @media (min-width: 1280px) {
    margin-bottom: 75px;
    text-align: center;
  }
`;

const Items = styled.ul`
  display: grid;
  gap: 60px;
  list-style: none;
  padding: 0;

  @media (min-width: 1280px) {
    grid-template-columns: repeat(3, 270px);
    gap: 160px;
    justify-content: center;
  }
`;

const ItemInner = styled.div`
  display: grid;
  gap: 30px;
`;

const ItemHeading = styled.p`
  margin-bottom: 0.4em;
  font-size: 20px;
  color: #192f5e;
`;

const ItemDescription = styled.p`
  max-width: 280px;
`;
