import {css} from 'styled-components';

import regular from '../fonts/Nunito-Regular.woff2';
import semiBold from '../fonts/Nunito-SemiBold.woff2';
import bold from '../fonts/Nunito-Bold.woff2';

export const FONT_FAMILY_NAME = 'Nunito';

export const fonts = css`
  @font-face {
    font-weight: normal;
    font-family: ${FONT_FAMILY_NAME};
    font-style: normal;
    font-display: swap;
    src: url(${regular}) format("woff2");
  }

  @font-face {
    font-weight: 600;
    font-family: ${FONT_FAMILY_NAME};
    font-style: normal;
    font-display: swap;
    src: url(${semiBold}) format("woff2");
  }

  @font-face {
    font-weight: 700;
    font-family: ${FONT_FAMILY_NAME};
    font-style: normal;
    font-display: swap;
    src: url(${bold}) format("woff2");
  }
`;