import * as React from 'react';
import styled from 'styled-components';

const links = [
  {
    name: '«Мои чеки онлайн»',
    href: 'https://mco.nalog.ru/login',
  },
  {
    name: 'Стоимость',
    href: '/price_convention.pdf',
  },
  {
    name: 'Жизненный цикл приложения',
    href: '/process_descriptions.pdf',
  },
  {
    name: 'Инструкция по настройке ПО',
    href: '/installation_manual.pdf',
  },
  {
    name: 'Инструкция по эксплуатации',
    href: '/user_guide.pdf',
  },
];

export const Links = () => (
  <Root>
    {links.map((link, index) => (
      <li key={index}>
        <Link href={link.href} target="_blank">
          {link.name}
        </Link>
      </li>
    ))}
  </Root>
);

const Root = styled.ul`
  list-style: none;
  padding: 0;

  @media (min-width: 1280px) {
    height: 95px;
    column-count: 2;
  }
`;

const Link = styled.a`
  display: block;
  margin-bottom: 0.6em;
  color: #6f7685;

  &:hover {
    color: #6895f5;
  }
`;
