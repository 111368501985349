import * as React from 'react';
import styled from 'styled-components';

import {Container} from '../common/container/container';
import {Logo} from './logo/logo';
import {Links} from './links';
import {Stores} from './stores/stores';

export const Footer = () => {
  return (
    <Root>
      <Container>
        <Inner>
          <Logo />
          <Links />
          <Stores />
        </Inner>
      </Container>
    </Root>
  );
};

const Root = styled.footer`
  border-top: 1px solid #eee;
  padding: 45px 0;
`;

const Inner = styled.div`
  display: grid;
  gap: 30px;
  
  @media (min-width: 1280px) {
    align-items: start;
    grid-template-columns: 200px 1fr auto;
  }
`;